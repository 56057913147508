import PocketBase from "pocketbase";
import Alpine from "alpinejs";
import sound from "./assets/audio/success.mp3";

// Importing game logic.
import * as game1 from "./js/game1";
import * as game3 from "./js/game3";
import * as game4 from "./js/game4";

// This file makes sure the HTML drag and drop API is supported on touch devices.
import "./js/draganddrop";

window.Alpine = Alpine;

Alpine.store("textbox_open", false);

Alpine.store("game_states", {
  game_1: {
    active: false,
    completed: false,
  },
  game_2: {
    active: false,
    completed: false,
  },
  game_3: {
    active: false,
    completed: false,
  },
  game_4: {
    active: false,
    completed: false,
  },
  is_completed(game_id) {
    return this[game_id].completed;
  },
  toggle_active(game_id) {
    this[game_id].active = !this[game_id].active;
  },
  go_to_next_game(game_id) {
    if (game_id === "game_4") {
      return;
    }
    const game_ids = Object.keys(this);
    const game_index = game_ids.indexOf(game_id);
    const next_game_id = game_ids[game_index + 1];
    this.toggle_active(game_id);
    this.toggle_active(next_game_id);
  },
});

Alpine.start();

// When the page is fully loaded, send a POST request to /api/games/start to start the game, and console.log the response.
window.addEventListener("load", async () => {
  const response = await fetch(
    "https://backend-actemium.fly.dev/api/games/start",
    {
      method: "POST",
    }
  );
  const data = await response.json();
  Alpine.store("game_states").toggle_active("game_1");
  console.log(data.message);
});

let countdownNumberEl = document.getElementById("countdown-number");
let countdown = 10 * 60;

countdownNumberEl.textContent = "10:00";

setInterval(function () {
  countdown = --countdown <= 0 ? 10 : countdown;
  let minutes = Math.floor(countdown / 60);
  let seconds = countdown - minutes * 60;

  countdownNumberEl.textContent = `0${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
}, 1000);

// Game 1 logic

// When the DOM is ready
document.addEventListener("DOMContentLoaded", () => {
  game1.initializeGame();

  document
    .getElementById("g1_check")
    .addEventListener("click", game1.checkGameCompletion);
  document
    .getElementById("g1_reset")
    .addEventListener("click", game1.resetGame);
});

// Game 3 logic
game3.cards.forEach((card) => card.addEventListener("click", game3.flipCard));
