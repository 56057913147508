const scissors = document.getElementById("scissors");

scissors.addEventListener("dragstart", function (e) {
  // This will make the original element invisible.
  this.style.opacity = "0";
  // Make the dragged element opaque.
  const clone = this.cloneNode(true);
  clone.style.opacity = "1";
  e.dataTransfer.setDragImage(clone, 40, 40);
});

scissors.addEventListener("dragend", function (e) {
  // This will make the original element visible again after dragging.
  this.style.opacity = "1";
});

const correctOrder = ["blue", "green", "cyan", "yellow"];
let cutOrder = [];

function startGame() {
  const wires = document.querySelectorAll(".wire");
  const cutWires = document.querySelectorAll(".cut_wire");
  // Display all the wires and hide all the uncut wires.
  wires.forEach((wire) => {
    wire.style.display = "block";
  });
  cutWires.forEach((cutWire) => {
    cutWire.style.display = "none";
  });
  cutOrder = []; // Empty the array so user can start over.

  wires.forEach((dropzone) => {
    // Remove all existing event listeners from the previous times the game was started.
    dropzone.removeEventListener("dragover", handleDragOver);
    // When a dragged element is hovered over a dropzone, call handleDragOver.
    dropzone.addEventListener("dragover", handleDragOver, { once: true });
  });
}

function handleDragOver(e) {
  // Replace the intact wire with the cut wire on dragover.
  this.style.display = "none";
  const cutWire = document.getElementById(`${this.id}_cut`);
  cutWire.style.display = "block";
  e.preventDefault();
  cutOrder.push(this.dataset.color);
  if (cutOrder.length === 4) {
    // Check whether user has cut wires in correct order, and if there's time left on the timer.
    if (
      cutOrder.join("") === correctOrder.join("") &&
      countdown.innerHtml !== "00:00"
    ) {
      console.log(cutOrder);
      console.log("You win!");
    } else {
      console.log(cutOrder);
      console.log("You lose!");
    }
  }
}

const resetButton = document.getElementById("reset");
resetButton.addEventListener("click", startGame);
startGame();

// TODO

// Check whether the wires are cut in the correct order.
// Check whether there is still time left on the clock.
// Add a reset button, abstract the initiation of the game away in a function that we trigger with the button.
// Show endscreen when the game is completed.
