// game1.js
export const draggable_elements = document.getElementsByClassName("g1_drag");
export const dropzone_elements = document.getElementsByClassName("g1_dropzone");
export const dragzone = document.getElementById("g1_dragzone");
import sound from "../assets/audio/success.mp3";
export let selected;

export const colors = [
  "red",
  "green",
  ["blue", "magenta"],
  "yellow",
  ["purple", "violet"],
  "orange",
  ["pink", "lightblue"],
  "brown",
];

export function initializeGame() {
  for (const drag of draggable_elements) {
    drag.addEventListener("dragstart", (event) => {
      selected = event.target;
    });
  }

  for (let index = 0; index < dropzone_elements.length; index++) {
    const drop = dropzone_elements[index];

    drop.addEventListener("dragover", (event) => {
      event.preventDefault();
    });

    drop.addEventListener("drop", (event) => {
      event.preventDefault();

      // If the dropzone already has one child, move it back to the dragzone
      if (drop.children.length === 1) {
        dragzone.appendChild(drop.children[0]);
        return;
      }

      // Check if the selected element has the data-correct attribute
      if (selected && selected.hasAttribute("data-correct")) {
        drop.style.backgroundColor = colors[index][0];
      } else {
        // Set to the single color
        drop.style.backgroundColor = colors[index][1];
      }
      // Append the selected element to the dropzone
      drop.appendChild(selected);
    });
  }
}

export function checkGameCompletion() {
  let completed = true;
  for (const drop of dropzone_elements) {
    // Verify if the dropzone has a child, and if the child has the data-correct attribute
    if (
      drop.children.length !== 1 ||
      !drop.children[0].hasAttribute("data-correct")
    ) {
      completed = false;
      break;
    }
  }
  if (completed) {
    Alpine.store("game_states").game_1.completed = true;
    if (Alpine.store("game_states").is_completed("game_1")) {
      const success_sound = new Audio(sound);
      success_sound.play();
      Alpine.store("game_states").go_to_next_game("game_1");
      Alpine.store("textbox_open", true);
    }
  }
}

export function resetGame() {
  for (const drop of dropzone_elements) {
    if (drop.children.length === 1 && !drop.hasAttribute("data-fixed")) {
      dragzone.appendChild(drop.children[0]);
      drop.style.backgroundColor = "";
    }
  }
}
